export default defineComponent({
  name: 'SubscriptionToggleModalContent',
  setup() {
    const { isSubscriptionActive } = useCustomerSubscriptions();
    const subscriptionStatus = isSubscriptionActive.value
      ? 'active'
      : 'inactive';

    return { subscriptionStatus };
  },
  render(): VNode {
    return (
      <div class="flex flex-col gap-4">
        <p>
          {this.$t(
            `subscription_toggle.${this.subscriptionStatus}.modal_content`
          )}
        </p>
      </div>
    );
  },
});
