import Button from '@/components/Button';
import type { VNode } from 'vue';

export default defineComponent({
  name: 'PauseCalendarModalActions',
  setup() {
    const modalStore = useModalStore();
    const handleCloseModal = (event: Event): void => {
      event.preventDefault();
      modalStore.closeModal(ModalType.Generic);
    };

    return {
      handleCloseModal,
    };
  },
  render(): VNode {
    return (
      <div class="flex w-full justify-center gap-4">
        <Button
          class="mx-auto my-4 button-stroke bg-white "
          onClick={(e) => {
            this.handleCloseModal(e);
          }}
        >
          {this.$t('subscription_toggle.active.modal_action_cancel')}
        </Button>
      </div>
    );
  },
});
