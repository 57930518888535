import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 23 15"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M16.188 8.542c-1.25 0-3.198.354-4.688 1.041-1.49-.698-3.437-1.041-4.687-1.041-2.26 0-6.771 1.125-6.771 3.385v2.865h22.916v-2.865c0-2.26-4.51-3.385-6.77-3.385Zm-4.167 4.687H1.604v-1.302c0-.562 2.667-1.823 5.209-1.823 2.541 0 5.208 1.26 5.208 1.823v1.302Zm9.375 0h-7.813v-1.302c0-.48-.208-.896-.541-1.27.916-.313 2.041-.553 3.146-.553 2.541 0 5.208 1.26 5.208 1.823v1.302ZM6.813 7.5a3.65 3.65 0 0 0 3.645-3.646A3.65 3.65 0 0 0 6.813.208a3.65 3.65 0 0 0-3.646 3.646A3.65 3.65 0 0 0 6.813 7.5Zm0-5.73a2.09 2.09 0 0 1 2.083 2.084 2.09 2.09 0 0 1-2.083 2.084 2.09 2.09 0 0 1-2.084-2.084 2.09 2.09 0 0 1 2.084-2.083Zm9.375 5.73a3.65 3.65 0 0 0 3.645-3.646A3.65 3.65 0 0 0 16.188.208a3.65 3.65 0 0 0-3.646 3.646A3.65 3.65 0 0 0 16.188 7.5Zm0-5.73a2.09 2.09 0 0 1 2.083 2.084 2.09 2.09 0 0 1-2.084 2.084 2.09 2.09 0 0 1-2.083-2.084 2.09 2.09 0 0 1 2.084-2.083Z"
    }, null, -1)
  ])))
}
export default { render: render }