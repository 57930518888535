import Button from '@/components/Button';
import PauseCalendarModalActions from '@/components/PauseCalendarModal/Actions';
import PauseCalendarModalContent from '@/components/PauseCalendarModal/Content';
import { ModalType } from '@/stores/useModalStore';
import type { EventProperties } from '@/utils/posthog';
import type { VNode } from 'vue';

export default defineComponent({
  name: 'SubscriptionToggleModalActions',
  props: {
    activationSource: {
      type: String as PropType<EventProperties['activationSource']>,
      required: true,
      default: 'deliveryCalendar',
    },
  },
  setup(props) {
    const { isSubscriptionActive, subscription } = useCustomerSubscriptions();

    const { setSubscriptionPaused } = useDeliveriesApi();
    const noticeStore = useNoticeStore();
    const { t } = useI18n();
    const modalStore = useModalStore();
    const route = useRoute();

    const subscriptionStatus = isSubscriptionActive.value
      ? 'active'
      : 'inactive';

    const activateSubscription = async (): Promise<void> => {
      if (!subscription.value) {
        return;
      }
      try {
        await setSubscriptionPaused(false, subscription.value.id);
        postHogSendCustomEvent('Subscription Activated', {
          activationSource: props.activationSource,
        });

        noticeStore.addNotice({
          text: t(`subscription_toggle.${subscriptionStatus}.notice_success`),
          type: 'success',
        });

        if (route.name !== 'delivery-calendar') {
          navigateTo({ name: 'delivery-calendar' });
        }
      } catch (_error) {
        noticeStore.addNotice({
          text: t('subscription_toggle.error'),
          type: 'caution',
        });
      }
    };

    return {
      activateSubscription,
      subscriptionStatus,
      modalStore,
    };
  },
  render(): VNode {
    return (
      <div class="flex w-full justify-center gap-4">
        <Button
          class="button-stroke"
          onClick={() => this.modalStore.closeModal(ModalType.Generic)}
        >
          {this.$t(
            `subscription_toggle.${this.subscriptionStatus}.modal_action_cancel`
          )}
        </Button>
        <Button
          class="button-primary"
          onClick={() => {
            if (this.subscriptionStatus === 'active') {
              this.modalStore.openModal(ModalType.Generic, {
                title: this.$t('calendar_pause_modal.header'),
                actions: <PauseCalendarModalActions />,
                content: <PauseCalendarModalContent />,
              });
            } else {
              this.activateSubscription();
              this.modalStore.closeModal(ModalType.Generic);
            }
          }}
        >
          {this.$t(
            `subscription_toggle.${this.subscriptionStatus}.modal_action`
          )}
        </Button>
      </div>
    );
  },
});
