import IconChartPieR from '@/assets/icons/icon-chart-pie-r.svg?component';
import IconCredit from '@/assets/icons/icon-credit.svg?component';
import IconCustomers from '@/assets/icons/icon-customers.svg?component';
import IconCutlery from '@/assets/icons/icon-cutlery.svg?component';
import IconDeliveries from '@/assets/icons/icon-deliveries.svg?component';
import IconDinnerDining from '@/assets/icons/icon-dinner-dining.svg?component';
import IconSupport from '@/assets/icons/icon-support.svg?component';
import type { CalendarPauseReason } from '@ruokaboksi/api-client';
import type { DefineComponent } from 'vue';
import PauseReasonToggle from './PauseReasonToggle';

// A simple shuffle to make the answers unbiased (people picking the first one more often)
const shuffleArray = (array: Array<unknown>) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export default defineComponent({
  name: 'PauseCalendarModalContent',
  setup() {
    const { t } = useI18n();
    const modalStore = useModalStore();
    const noticeStore = useNoticeStore();
    const { setSubscriptionPaused } = useDeliveriesApi();
    const { subscription } = useCustomerSubscriptions();

    const defaultCallBack = async (reason: CalendarPauseReason) => {
      modalStore.closeModal(ModalType.Generic);
      if (subscription.value) {
        try {
          await setSubscriptionPaused(true, subscription.value.id, reason);
          noticeStore.addNotice({
            text: t('subscription_toggle.active.notice_success'),
            type: 'success',
          });
        } catch (_error) {
          noticeStore.addNotice({
            text: t('subscription_toggle.error'),
            type: 'caution',
          });
        }
      }
    };

    const pauseReasons: {
      reasonNormalised: CalendarPauseReason;
      reasonText: string;
      callBack: (reason: CalendarPauseReason) => void;
      icon: DefineComponent<{ height?: string; width?: string }>;
    }[] = [
      {
        reasonNormalised: 'absence',
        reasonText: t('calendar_pause_modal.reason.absence'),
        callBack: defaultCallBack,
        icon: IconCustomers,
      },
      {
        reasonNormalised: 'budget',
        reasonText: t('calendar_pause_modal.reason.budget'),
        callBack: defaultCallBack,
        icon: IconChartPieR,
      },
      {
        reasonNormalised: 'cost',
        reasonText: t('calendar_pause_modal.reason.cost'),
        callBack: defaultCallBack,
        icon: IconCredit,
      },
      {
        reasonNormalised: 'recipeSelection',
        reasonText: t('calendar_pause_modal.reason.recipe_selection'),
        callBack: defaultCallBack,
        icon: IconCutlery,
      },
      {
        reasonNormalised: 'deliveryTime',
        reasonText: t('calendar_pause_modal.reason.delivery_time'),
        callBack: defaultCallBack,
        icon: IconDeliveries,
      },
      {
        reasonNormalised: 'ingredients',
        reasonText: t('calendar_pause_modal.reason.ingredients'),
        callBack: defaultCallBack,
        icon: IconDinnerDining,
      },
    ];

    const answers = pauseReasons;
    shuffleArray(answers);
    answers.push({
      reasonNormalised: 'other',
      reasonText: t('calendar_pause_modal.reason.other'),
      callBack: defaultCallBack,
      icon: IconSupport,
    });

    return {
      reasonsShuffled: answers,
    };
  },

  render(): VNode | null {
    return (
      <div class="flex flex-col align-left">
        {this.reasonsShuffled.map((reason) => (
          <PauseReasonToggle
            pauseReason={reason.reasonText}
            pauseReasonNormalised={reason.reasonNormalised}
            onClick={reason.callBack}
            icon={reason.icon}
          />
        ))}
      </div>
    );
  },
});
