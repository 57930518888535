import type { DefineComponent, VNode } from 'vue';

export default defineComponent({
  props: {
    pauseReasonNormalised: {
      type: String,
      required: true,
    },
    pauseReason: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    icon: {
      type: Object as PropType<
        DefineComponent<{
          height?: string;
          width?: string;
        }>
      >,
      required: true,
    },
  },
  name: 'PauseReasonToggle',
  setup() {
    return {};
  },
  render(): VNode {
    return (
      <button
        class="mx-auto flex gap-6 w-full border-1 border-rb-gray p-2 m-1 rounded-sm hover:-translate-x-[-4px]"
        id={this.pauseReasonNormalised + 'div'}
        onClick={() => this.onClick(this.pauseReasonNormalised)}
      >
        <div class="flex gap-2">
          <this.icon class="w-6 h-6" />
        </div>
        <span class="text-md font-medium text-left">{this.pauseReason}</span>
      </button>
    );
  },
});
